import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  withCredentials: true,
});

const REACT_APP_CRM_InstanceURL = "/api/SpinWheel/";

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("401");
    }
    return error;
  }
);

export const GetListPrize = async (token) => {
  const MemberHeader = {
    headers: {
      Authorization: token
    },
  };
  return instance.post(REACT_APP_CRM_InstanceURL + 'GetPrizes', {}, MemberHeader);
};

export const GetChances = async (token) => {
  const MemberHeader = {
    headers: {
      Authorization: token
    },
  };
  return instance.post(REACT_APP_CRM_InstanceURL + 'GetNumberOfChances', {}, MemberHeader);
};

export const GenerateAndRedeemPrizeWon =  async (token) => {
  const MemberHeader = {
    headers: {
      Authorization: token
    },
  };
  return instance.post(REACT_APP_CRM_InstanceURL + 'GenerateAndRedeemPrizeWon', {}, MemberHeader);
};