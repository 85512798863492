import React, { Component } from "react";
// import { connect } from 'react-redux';
import "./PopUp.scss";

class PopUp extends Component {
  openProfilePage = () => {
    window.location.href = process.env.REACT_APP_LEVIS_PROFILE;
  };

  render() {
    if (this.props.statusMessage === "SUCCESS") {
      return (
        <div className="pyro">
          <div className="before"></div>
          <div className="popup-wrapper">
            <div className="popup-background">
              <div className="popup-heading">
                당첨! <br /> 축하 드립니다.
              </div>
              <div className="popup-prize">
                <div className="popup-prize-text">
                  현금 {this.props.item} 상당의 리바이스 쿠폰을 받으셨습니다.
                </div>
                <div className="popup-prize-text">
                  쿠폰을 확인하시려면 마이 프로필 페이지로 이동하세요.
                </div>
              </div>
              <div className="after"></div>

              <div className="popup-button">
                <button className="button" onClick={this.openProfilePage}>
                  마이 프로필로 가기
                </button>
              </div>
              {this.props.chances > 0 ? (
                <div className="popup-button">
                  <button className="button" onClick={this.props.onClick}>
                    게임 다시 하기
                  </button>
                </div>
              ) : (
                <div className="popup-button">
                  <div className="button disabled">남은 기회: 0</div>
                </div>
              )}
            </div>
          </div>

          <div className="after"></div>
        </div>
      );
    } else if (this.props.statusMessage === "INACTIVE") {
      return (
        <div className="popup-wrapper">
          <div className="popup-background-warning">
            {/* <div className='popup-background-warning-content'> */}
            <div className="popup-heading">안내 !!!</div>
            <div className="popup-prize-warning">
              캠페인이 종료 되었습니다. <br /> 다음 기회에 참여해 주세요.
            </div>
            <div className="popup-button">
              <button className="button" onClick={this.props.onClick}>
                닫기
              </button>
            </div>
            {/* </div> */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="popup-wrapper">
          <div className="popup-background-warning">
            <div className="popup-heading">오류 !!!</div>
            <div className="popup-prize-warning">
              오류가 발생했습니다. 다시 시도해 주세요.
            </div>
            <div className="popup-button">
              <button className="button" onClick={this.props.onClick}>
                닫기
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PopUp;
