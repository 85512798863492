import React, { Component } from "react";
import { connect } from "react-redux";
import SpinRound from "../../../src/assets/images/Round.png";
import SpinIcon from "../../../src/assets/images/SpinIcon.png";
import "./Home.scss";
import * as API from "../../services";
import PopUp from "../../components/PopUp/PopUp";
import "reactjs-popup/dist/index.css";
import { IS_DEV } from "../../utils";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PopUpState: false,
      popUpLoginState: false,
      PopUpStatus: "",
      countnameAni: 0,
      codes: [],
      rewardCampaignHistory: {},
      prize: "",
      rewardPrize: {},
      code: undefined,
      timeInfo: "",
      descriptionInfo: "",
      checkSpin: false,
      rotateStart: 0,
      countNameAni: 0,
      memberCardNo: "",
      description: "",
      emptyRewards: false,
      tokenLocal: "",
      totalAvailableChances: 0
    };
    this.btnSpin = React.createRef();
    this.handleSpin = this.handleSpin.bind(this);
    this.wheel = React.createRef()
  }

  handleSpin(random) {
    const styleSheets = document.styleSheets[document.styleSheets.length - 1];
    styleSheets.removeRule(styleSheets.cssRules.length - 1);
    const rotate = 5 * 360 + (-360 * random) / 5 - 25;
    const nameAni = `spin-wheel-${this.state.countnameAni}`;
    this.setState({ countnameAni: this.state.countnameAni + 1 });
    const keyFrames = `@-webkit-keyframes ${nameAni} {
      from {transform: rotate(${this.state.rotateStart}deg)}
      to {transform: rotate(${rotate}deg)}
    }`
    styleSheets.insertRule(keyFrames, styleSheets.cssRules.length);
    this.wheel.current.style.animationName = nameAni;
    this.wheel.current.style.animationTimingFunction = 'ease-in-out';
    this.wheel.current.style.animationDuration = '2.5s';
    this.wheel.current.style.transform = `rotate(${rotate}deg)`;
    this.setState({ rotateStart: rotate%360 });
    setTimeout(() => {
      this.setState({
          checkSpin: false,
          PopUpState: true,
          PopUpStatus: "SUCCESS",
      })
    }, 3000)
  }

  componentDidMount = async () => {
    const query = new URLSearchParams(window.location.search);
    let tokenLocal = query.get("access_token");
    if (!tokenLocal && !IS_DEV) {
      let domain = new URL(process.env.REACT_APP_LEVIS_PROFILE);
      domain = domain.hostname;
      window.location.href = `https://${domain}/s/LeviKR/home?returnurl=${process.env.REACT_APP_BACKEND_URL}`;
      return;
    }
    // https://staging-amastore-lsco.demandware.net/on/demandware.store/Sites-LeviKR-Site/ko_KR/HomePage-loyaltyPoint
    // https://staging-amastore-lsco.demandware.net/s/LeviKR/home
    this.setState({
      tokenLocal
    })
    const listPrize = await API.GetListPrize(tokenLocal);

    if (listPrize && listPrize.response && listPrize.response.status === 401) {
      this.setState({
        popUpLoginState: true,
      });
      return;
    }
    if (listPrize && listPrize.data && listPrize.data.result) {
      this.setState({
        codes: listPrize.data.result
      });
    }
    const chances = await API.GetChances(tokenLocal);
    if (chances && chances.data && chances.data.result) {
      this.setState({
        totalAvailableChances: chances.data.result.totalAvailableChances
      });
    }
  };

  handleClick = async () => {
    if (!this.state.checkSpin) {
      this.setState({
        checkSpin: true,
      });
      const prize = await API.GenerateAndRedeemPrizeWon(this.state.tokenLocal);
      console.log(prize)
      if (prize && prize.response && prize.response.status === 401) {
        this.setState({
          popUpLoginState: true,
        });
        return;
      } else if (prize && prize.response && prize.response.status === 400) {
        if (prize.response.data.message.includes('campaign has expired')) {
          this.setState({
            PopUpState: true,
            checkSpin: false,
            PopUpStatus: "INACTIVE",
          });
        }
      }
      if (prize && prize.data && prize.data.result) {
        var random = this.state.codes.findIndex( x=> x.customCode === prize.data.result.customCode);
        this.handleSpin(random);
        this.setState({
          rewardPrize: {
            Name: prize.data.result.name
          }
        });
        // setTimeout(() => {
        //   this.setState({
        //     PopUpState: true,
        //     PopUpStatus: "SUCCESS",
        //     checkSpin: false,
        //   });
        // }, 1000);
        const chances = await API.GetChances(this.state.tokenLocal);
        if (chances && chances.data && chances.data.result) {
          this.setState({
            totalAvailableChances: chances.data.result.totalAvailableChances
          });
        }
      }
    }
  };

  render() {
    return (
      <div className="home-wrapper">
        {this.state.PopUpState && (
          <PopUp
            statusMessage={this.state.PopUpStatus}
            chances={this.state.totalAvailableChances}
            item={this.state.rewardPrize.Name}
            onClick={() => this.setState({ PopUpState: false })}
          />
        )}
        {this.state.popUpLoginState && (
          <PopUp
            statusMessage={this.state.PopUpStatus}
            onClick={() => this.setState({ popUpLoginState: false })}
          />
        )}

        <div className="home-container">
          <div className="bg-container">
            <div className="round">
            <div className="icon-round-box">
                <div className="box-round-img list-voucher" ref={this.wheel}>
                  <img src={SpinRound} alt="" className="icon-round" />
                  <div
                    className="voucher-box"
                    style={{}}
                    onClick={this.handleClick}
                  >
                    {this.state.codes &&
                      this.state.codes.map((item, index) => {
                        const splitIndex = ["voucher", "cash"].reduce(
                          (idx, subfix) =>
                            idx === -1 ? item.name.indexOf(subfix) : idx,
                          -1
                        );

                        const name = item.name.substring(0, splitIndex).trim();
                        const voucher = item.name
                          .substring(splitIndex, item.name.length)
                          .trim();
                        return (
                          <li key={index} className="voucher-item">
                            <p className="text-no-voucher">{name}</p>
                            <p className="text-voucher">{voucher}</p>
                          </li>
                        );
                      })}
                  </div>
                </div>
                <div ref={this.btnSpin} className="spin" onClick={this.handleClick}>
                    <img src={SpinIcon} alt="" className="icon-spin" />
                </div>
              </div>
            </div>

            <div className="content">
              <p className="title">룰렛 이벤트</p>
              <p className="description">
                기간 : 2024년 2월 15일 11 AM ~ 2월 29일 12 AM
                <br />
                100% 당첨 룰렛 이벤트에 참여하고 최대 5,000원의 Levi's® 쿠폰을 받으세요!
                <br />
                <br />
                Levi's® 레드탭 멤버십에 가입하신 모든 분들께 1 번의 룰렛게임 참여기회가 주어집니다. 당첨된 혜택은 나의 혜택 > 쿠폰에서 확인할 수 있습니다.
              </p>
              {/* <p className="chances">
                Spins left: {this.state.totalAvailableChances || 0}
              </p> */}
            </div>
          </div>

          <div className="footer-container">
            <div className="terms">
              <p className="terms-title">유의사항:</p>
              <p className="terms-content">
                - Levi's® 레드탭 멤버라면 누구든지 참여할 수 있습니다.
                <br />
                - 아이디당 1번의 기회가 주어집니다.
                <br />
                - 당첨된 혜택은 나의 혜택 > 쿠폰에서 확인할 수 있으며, 발급일로부터 2주간 유효합니다.
                <br />
                - 룰렛이벤트 쿠폰은 LVC, 콜라보레이션 및 일부 제품은 적용 불가이며, 다른 쿠폰과는 함께 사용할 수 없습니다.
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.admin.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
